import React from 'react';
import styled from 'styled-components';
// import debounce from 'lodash.debounce';
import BullEye from './BullEye';
import getData from '../api';
import LineChart from './LineChart';

const Wrapper = styled.div`
  .currentData {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const Component = () => {
  const [data, setData] = React.useState();
  const [state, setState] = React.useState('init');
  // const [dataReducer, setDataReducer] = React.useState(
  //   parseInt(1440 / parseInt(window.innerWidth, 10), 10),
  // );

  // const setDataReducerFn = debounce(() => {
  //   setState('resize');
  //   setDataReducer(parseInt(1440 / parseInt(window.innerWidth, 10), 10));
  // }, 500);

  // React.useEffect(() => {
  //   window.addEventListener('resize', setDataReducerFn);
  //   return () => {
  //     window.removeEventListener('resize', setDataReducerFn);
  //   };
  // }, []);

  React.useEffect(() => {
    setData(null);
    setState('fetching data...');
    getData()
      .then((d) => {
        setState('data received');
        setData(d);
      })
      .catch((err) => {
        setState('error');
        console.log(err);
      });
  }, []);

  const classes = ['Chart'];

  const formattedData = data
    && data.reduce(
      (acc, curr) => {
        // if (index % (dataReducer * 2) === 0) {
        acc.temperature.push(curr.data.temp);
        acc.rh.push(curr.data.rh);
        const timestamp = new Date(curr.data.timestamp);
        acc.time.push(`${timestamp.getHours()}:${timestamp.getMinutes()}`);
        // }

        return acc;
      },
      { temperature: [], rh: [], time: [] },
    );

  return (
    <Wrapper className={classes.join(' ')}>
      {!formattedData && <h1>{state}</h1>}
      {!!formattedData && (
        <>
          <LineChart
            height="30vh"
            data={{
              labels: formattedData.time,
              datasets: [
                {
                  label: 'Temperature',
                  data: formattedData.temperature,
                  backgroundColor: ['rgba(255, 99, 132, 0.2)'],
                  borderColor: ['rgba(255, 99, 132, 1)'],
                  borderWidth: 1,
                },
              ],
            }}
          />

          <LineChart
            height="30vh"
            data={{
              labels: formattedData.time,
              datasets: [
                {
                  label: 'Humidity',
                  data: formattedData.rh,
                  backgroundColor: ['rgba(54, 162, 235, 0.2)'],
                  borderColor: ['rgba(54, 162, 235, 1)'],
                  borderWidth: 1,
                },
              ],
            }}
          />
          <div className="currentData">
            <BullEye bgColor="rgba(255, 99, 132, 0.2)">
              {formattedData.temperature[0]}
              <small>&deg;</small>
            </BullEye>
            <BullEye bgColor="rgba(54, 162, 235, 0.2)">
              {formattedData.rh[0]}
              <small>%</small>
            </BullEye>
          </div>
        </>
      )}
    </Wrapper>
  );
};

Component.displayName = 'Chart';

export default Component;
