const devUrl = 'http://localhost:4200/';
const prodUrl = 'https://homeapi.freeconomy.network/';

export default (n) => {
  const searchParams = n ? new URLSearchParams({ n }) : '';
  const dataUrl = 'last_cached_data';

  const apiUrl = process.env.NODE_ENV === 'development' ? devUrl : prodUrl;
  return fetch(`${apiUrl}${dataUrl}?${searchParams}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
    },
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }

      return res.json().then((data) => data);
    })
    .catch((err) => console.error(err));
};
