/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius:100%;
  font-size: 2rem;
  padding: .5em;
  width: 3em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:${({ bgColor }) => bgColor};
`;

const Component = ({ className, children, bgColor = '#f0f0f0' }) => {
  const classes = ['BullEye'];
  if (className) classes.push(className);

  return (
    <Wrapper bgColor={bgColor} className={classes.join(' ')}>
      {children}
    </Wrapper>
  );
};

Component.displayName = 'BullEye';

export default Component;
